var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',[_c('v-toolbar-title',{staticClass:"pr-8"},[_vm._v("Pending sales summary")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-progress-linear',{attrs:{"active":_vm.isLoading(),"indeterminate":true,"absolute":"","bottom":"","color":"blue-grey darken-3"}})],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.pendingSales,"items-per-page":100,"dense":"","multi-sort":""},scopedSlots:_vm._u([{key:"item.total_extended_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.total_extended_price))+" ")]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.count))+" ")]}},{key:"item.min_trans_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.min_trans_date))+" ")]}},{key:"item.max_trans_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.max_trans_date))+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }