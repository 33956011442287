<template>
  <v-container class="my-5">
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-toolbar-title class="pr-8">Pending sales summary</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-progress-linear
            :active="isLoading()"
            :indeterminate="true"
            absolute
            bottom
            color="blue-grey darken-3"
          ></v-progress-linear>
        </v-toolbar>

        <v-card>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="pendingSales"
            :items-per-page="100"
            class="elevation-1"
            dense
            multi-sort
          >
            <template v-slot:item.total_extended_price="{ item }">
              {{ formatMoney(item.total_extended_price) }}
            </template>

            <template v-slot:item.count="{ item }">
              {{ formatNumber(item.count) }}
            </template>

            <template v-slot:item.min_trans_date="{ item }">
              {{ formatDate(item.min_trans_date) }}
            </template>

            <template v-slot:item.max_trans_date="{ item }">
              {{ formatDate(item.max_trans_date) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api";
import { formatMoney, formatDate, formatNumber } from "@/lib/utils";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Pipeline ID", value: "pipeline_id" },
        { text: "Pipeline", value: "pipeline_name" },
        { text: "Data type", value: "data_type" },
        { text: "Min trans date", value: "min_trans_date" },
        { text: "Max trans date", value: "max_trans_date" },
        { text: "Total extended", value: "total_extended_price" },
        { text: "Average age", value: "average_age" },
        { text: "Count", value: "count" },
      ],

      pendingSales: [],

      loading: false,
    };
  },

  methods: {
    isLoading() {
      return this.loading;
    },

    formatMoney(value) {
      // stupid, but here so the linter doesn't complain
      return formatMoney(value);
    },

    formatNumber(value) {
      // stupid, but here so the linter doesn't complain
      return formatNumber(value);
    },

    formatDate(value) {
      // stupid, but here so the linter doesn't complain
      return formatDate(value);
    },
  },

  mounted() {
    this.loading = true;

    api.getPendingSaleSummary({}).then((response) => {
      this.loading = false;
      this.pendingSales = response.data.rows;
    });
  },
};
</script>

<style></style>
